import csstype.*
import react.FC
import react.Props
import emotion.react.css
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useState

external interface WelcomeProps : Props {
    var name: String
}

val Welcome = FC<WelcomeProps> { props ->
    val name by useState(props.name)
    div {
        css {
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            backgroundColor = rgb(222, 242, 242)
        }
        img {
            src = "images/logo.svg"
        }
    }
    div {
        css {
            backgroundColor = rgb(84, 146, 49)
            fontFamily = FontFamily.cursive
            fontSize = 45.px
            fontWeight = FontWeight.bolder
            color = rgb(119, 201, 43)
            textAlign = TextAlign.center
            padding = 5.px
        }
        +name
    }
    div {
        css {
            fontFamily = FontFamily.monospace
            fontSize = 30.px
            fontWeight = FontWeight.bolder
            color = rgb(129, 149, 118)
            textAlign = TextAlign.center
        }
        +"Coming Soon!"
    }
}